<template>
	<v-app-bar app clipped-left color="#FFFFFF" elevation="1">
		<!-- <v-app-bar-nav-icon @click="drawer = !drawer" /> -->
		<v-spacer />
		<v-btn small text class="font-weight-light  text-capitalize" @click="dialog">Log out</v-btn>
		<v-tooltip down>
			<template v-slot:activator="{ on }">
				<v-btn icon x-small>
					<v-badge color="error" overlap class="px-1">
						<template slot="badge">0</template>
						<v-icon color="tertiary" v-on="on">mdi-bell</v-icon>
					</v-badge>
				</v-btn>
			</template>
			<span>view notification</span>
		</v-tooltip>
		<v-avatar size="35" class="ml-4">
			<img :src="'https://ui-avatars.com/api/?name=' + profile.user_firstname + profile.user_lastname" :alt="profile.user_firstname + ' ' + profile.user_lastname" />
		</v-avatar>
		<small class="ml-2">{{ profile.user_firstname }} {{ profile.user_lastname }}</small>
	</v-app-bar>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	props: {
		dialog: Function
	},
	computed: mapGetters({
		profile: "user"
	})
};
</script>

<style scoped>
.wrapper {
	padding-left: 5px;
	padding-right: 5px;
}
.profile-img {
	border-radius: 50%;
	height: 40px;
	width: 40px;
}
</style>
