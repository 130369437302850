<template>
  <v-app id="dashboard">
    <DashboardHeader :dialog="showDialog" />
    <DashboardDrawer />
    <v-main class="mx-12">
      <v-row justify="start">
        <v-col cols="12" class="pt-8">
          <p class="font-weight-bold h5">My DashBoard</p>
        </v-col>
        <v-col cols="12" md="6">
          <Profile />
        </v-col>
        <v-col cols="12" md="6">
          <Plan />
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col>
          <DependantList />
        </v-col>
      </v-row>

      <!-- logout modal -->
      <v-dialog v-model="dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Attention</v-card-title>

          <v-card-text
            >You are about to be signed out. Do you want to
            continue?</v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn small color="green darken-1" text @click="signOut"
              >Proceed</v-btn
            >
            <v-btn small color="danger darken-1" text @click="dialog = false"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="desserts"
        hide-default-footer
        class="elevation-1"
      ></v-data-table>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DashboardHeader from "@/views/layouts/dashboard/DashboardHeader.vue";
import DashboardDrawer from "@/views/layouts/dashboard/DashboardDrawer.vue";
import DependantList from "./components/Dashboard/DependantsList.vue";
import Profile from "./components/Dashboard/Profile.vue";
import Plan from "./components/Dashboard/Plan.vue";

export default {
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone" },
        { text: "State", value: "state" },
        { text: "ID", value: "id" },
      ],
      desserts: [
        // {
        //   name: "",
        //   email: "",
        //   phone: "",
        //   stae: "",
        //   id: "",
        // },
      ],
      // tabs: [
      //   {
      //     id: 1,
      //     title: "Total Health Care Costs",
      //     price: "12,500"
      //   },
      //   {
      //     id: 2,
      //     title: "You have used",
      //     price: "7,700"
      //   },
      //   {
      //     id: 3,
      //     title: "Your 2020 medical deductible",
      //     price: "800"
      //   },
      //   {
      //     id: 4,
      //     title: "Doctor visits",
      //     price: "39"
      //   },
      //   {
      //     id: 5,
      //     title: "Transactions",
      //     price: "47"
      //   }
      // ]
    };
  },
  components: {
    DashboardHeader,
    DashboardDrawer,
    DependantList,
    Profile,
    Plan,
  },
  methods: {
    ...mapActions(["clearUserSession"]),
    // clears user session and logs the user out
    async signOut() {
      // Clears logged in user session and redirects to `OpenHISA home page` if successful
      this.clearUserSession() && this.$router.push("/");
      // toggle modal dialog as `closed`
      this.dialog = false;
    },
    showDialog() {
      // toggle modal dialog as `open`
      this.dialog = true;
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style>
#keep .v-navigation-drawer__border {
  display: none;
}
</style>
