<template>
  <v-row>
    <v-col cols="12" class="text-left">
      <v-row>
        <v-col cols="3">
          <template>
            <div class="text-center">
              <v-dialog v-model="dialog" :width="isLoading ? 200 : 600">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="green darken-4"
                    class="white--text font-weight-bold mr-2 w-100"
                    medium
                  >
                    <span class="mr-2">Add dependant</span>
                    <v-icon>mdi-account-plus</v-icon>
                  </v-btn>
                </template>
                <!-- Add dependant loading indicator -->
                <v-card class="p-12" v-if="isLoading">
                  <v-card-title class="py-12">
                    <v-progress-circular
                      :width="4"
                      :size="30"
                      color="green darken-4"
                      class="mx-auto"
                      indeterminate
                    ></v-progress-circular>
                  </v-card-title>
                </v-card>
                <!-- Add dependant card -->
                <v-card class="px-12" v-else>
                  <v-card-title primary-title class="display-1 p-4"
                    >Add dependant</v-card-title
                  >
                  <v-container>
                    <v-form ref="form">
                      <v-text-field
                        label="First Name"
                        v-model="dependant.first_name"
                        :rules="[(value) => !!value || 'First name required.']"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        label="Last Name"
                        v-model="dependant.last_name"
                        :rules="[(value) => !!value || 'Last name required.']"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        label="Middle Name"
                        v-model="dependant.middle_name"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        label="Email"
                        v-model="dependant.email"
                        :rules="[(value) => !!value || 'Email required.']"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        label="Phone"
                        type="number"
                        v-model="dependant.phone"
                        :rules="[(value) => !!value || 'Phone required.']"
                        outlined
                        dense
                      ></v-text-field>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dependant.date_of_birth"
                            label="Birthday date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="dependant.date_of_birth"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1950-01-01"
                          color="green darken-4"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                      <v-radio-group
                        mandartory
                        row
                        v-model="dependant.marital_status"
                      >
                        <template v-slot:label>
                          <div>Marital status</div>
                        </template>
                        <v-radio
                          v-for="maritalStatus in [
                            'Single',
                            'Married',
                            'Divorced',
                          ]"
                          :key="maritalStatus"
                          :label="maritalStatus"
                          :value="maritalStatus"
                          color="green darken-4"
                        ></v-radio>
                      </v-radio-group>
                      <v-radio-group mandartory row v-model="dependant.gender">
                        <template v-slot:label>
                          <div>Gender</div>
                        </template>
                        <v-radio
                          v-for="gender in ['Male', 'Female']"
                          :key="gender"
                          :label="gender"
                          :value="gender"
                          color="green darken-4"
                        ></v-radio>
                      </v-radio-group>
                      <v-text-field
                        label="Nationality"
                        v-model="dependant.nationality"
                        :rules="[(value) => !!value || 'Nationality required.']"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        label="State"
                        v-model="dependant.state"
                        :rules="[(value) => !!value || 'State required.']"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        label="LGA"
                        v-model="dependant.lga"
                        :rules="[(value) => !!value || 'LGA required.']"
                        outlined
                        dense
                      ></v-text-field>
                      <v-text-field
                        label="State of Residence"
                        v-model="dependant.state_of_residence"
                        :rules="[(value) => !!value || 'State required.']"
                        outlined
                        dense
                      ></v-text-field>
                    </v-form>
                  </v-container>
                  <v-divider></v-divider>
                  <v-card-actions class="p-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-4"
                      class="font-weight-bold white--text"
                      @click="addDependant"
                      >Add</v-btn
                    >
                    <v-btn
                      color="red darken-4"
                      class="font-weight-bold white--text"
                      @click="dialog = false"
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </v-col>
        <v-col cols="6" class="d-flex align-items-start">
          <v-file-input
            :disabled="dependantsExists"
            label="Select CSV file"
            dense
            outlined
          ></v-file-input>
          <v-btn
            :disabled="dependantsExists"
            medium
            color="green darken-4"
            class="white--text font-weight-bold ml-3"
          >
            upload
            <v-icon>mdi-upload</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="mt-8" v-if="dependantsExists">
      <v-card>
        <v-card-title class="ml-2">
          List of dependents ({{ desserts.length }})
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="5"
          class="elevation-1"
          show-select
          item-key="name"
          v-model="selected"
          :search="search"
        >
          <template v-slot:item.name="{ item }">
            <v-btn icon>
              <v-img
                max-width="40"
                class="profile-img"
                :src="item.image"
              ></v-img>
            </v-btn>
            {{ item.name }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "dependant-list",
  data() {
    return {
      menu: false,
      dependant: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone: "",
        date_of_birth: "",
        marital_status: "",
        gender: "",
        nationality: "",
        state: "",
        lga: "",
        state_of_residence: "",
      },
      selected: [],
      search: "",
      dialog: false,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        { text: "Email", value: "email", sortable: true },
        { text: "Phone", value: "phone", sortable: true },
        { text: "Date of Birth", value: "date_of_birth", sortable: true },
        { text: "Relationship", value: "relationship", sortable: false },
        { text: "Date of Birth", value: "dateofbirth", sortable: true },
        { text: "No. of Claims", value: "claims", sortable: true },
      ],
      desserts: [
        // {
        //   name: "Aisha Ibrahim",
        //   email: "aishaibrahim@gmail.com",
        //   relationship: "Sister",
        //   dateofbirth: "24/1/1991",
        //   claims: 16,
        //   image: `https://picsum.photos/500/300?image=${5 * 5 + 10}`
        // },
      ],
    };
  },
  methods: {
    ...mapActions({ registerDependant: "enrollmentModule/registerDependant", fetchDependants: "enrollmentModule/fetchDependants" }),
    save(date) {
      this.$refs.menu.save(date);
    },
    async addDependant() {
      const self = this;

      if (this.$refs.form.validate()) {
        await this.registerDependant({
          data: {...self.dependant },
        });

        this.dialog = false;

        // fetches backend data for registered dependants
        const dependants = await this.fetchDependants();

        // filter dependants based on current User ID (user_id)
        const desertResult = dependants.filter(dr => dr.parent_data === self.user.user_id)

        // push registered dependant to data-table `desert`
        desertResult.map(result => self.desserts.push({
          name: `${result.first_name} ${result.middle_name} ${result.last_name}`,
          email: `${result.email}`,
          dateofbirth: `${result.date_of_birth}`
        }))
        
        ;
      }
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters({ isLoading: "enrollmentModule/loading", user: "user" }),
    dependantsExists() {
      return this.desserts.length > 0;
    },
  },
};
</script>

<style scoped>
.profile-img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 20px;
}
/* .tr:hover {
  background: red;
}
.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  background: pink;
} */
</style>
