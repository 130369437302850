<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    color="#0E762D"
    :mini-variant.sync="mini"
    permanent
    class="d-flex flex-column side-bar"
    width="150px"
  >
    <v-list dense class="mt-12 pt-10">
      <template v-for="(item, i) in items">
        <v-list-item :key="i" link class="list-item mt-1">
          <v-icon medium class="mr-3 white--text">{{ item.icon }}</v-icon>
          <v-list-item-content>
            <small class="font-weight-bold white--text">{{ item.text }}</small>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-container class="mt-auto fixed-bottom">
      <v-btn small outlined class="w-100" color="white">Feedback</v-btn>
    </v-container>
  </v-navigation-drawer>
</template>


<script>
export default {
  data: () => ({
    drawer: null,
    mini: false,
    items: [
      { icon: "mdi-book", text: "Dashboard" },
      { icon: "mdi-archive", text: "Analytics" },
      { icon: "mdi-help", text: "Help" },
    ],
  }),
};
</script>
<style scoped>
.side-bar {
  background: transparent linear-gradient(180deg, #0e762d 0%, #165429 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}
</style>
