
<template>
  <v-card outlined class="px-6 h-100" min-width="100%">
    <v-container fluid>
      <!-- informal plan -->
      <!-- <v-row v-if="profile.plan_name === 'Informal'" justify="start" align="center">
        <v-col cols="12" class="text-right">
          <router-link to="#">
            <small>Make Changes</small>
          </router-link>
        </v-col>
        <v-col cols="4">
          <p class="display-4 font-weight-bold my-0">{{days}}</p>
          <strong>Days left</strong>
        </v-col>
        <v-col cols="8" class="p-1">
          <p class="m-0">{{profile.plan_name}} Plan</p>
          <p class="font-weight-bold mb-0 display-1">{{price}}</p>
          <p class="m-0">Primary HCP: {{profile.primary_hcp}}</p>
          <p>No of dependents: 0</p>
        </v-col>
      </v-row> -->
      <!-- other plans -->
      <!-- <v-row v-else justify="start" align="center">
        <v-col cols="12" class="text-right">
          <router-link to="#">
            <small>Make Changes</small>
          </router-link>
        </v-col>
        <v-col cols="4">
          <p class="display-4 font-weight-bold my-0">{{days}}</p>
          <strong>Days left</strong>
        </v-col>
        <v-col cols="8" class="p-1">
          <p class="m-0">{{profile.plan_name}} Plan</p>
          <p class="font-weight-bold mb-0 display-1">{{price}}</p>
          <p>No of dependents: 0</p>
        </v-col>
      </v-row> -->
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "dashboard-plan",
  data() {
    return {
      price: "12,500",
      clinic: "OLD MEDICAL CLINIC",
      dependents: 3,
    };
  },
  computed: {
    ...mapGetters({ profile: "currentUser" }),
    // days() {
    //   const difference = new Date(this.profile.expired).getTime() - Date.now();

    //   return Math.round(difference / (1000 * 3600 * 24));
    // },
  },
};
</script>

<style scoped>
/* .plan-day {
	line-height: 30px;
} */
</style>
