<template>
  <v-card outlined class="px-6 h-100" min-width="100%">
    <v-container fluid>
      <v-row justify="start" align="center">
        <v-col cols="12" md="3" class="text-align-center">
          <v-avatar size="100">
            <img :src="'https://ui-avatars.com/api/?name=' + profile.user_firstname + ' ' + profile.user_lastname" :alt="profile.user_firstname + ' ' + profile.user_lastname" />
          </v-avatar>
        </v-col>
        <v-col cols="12" md="6" class="px-2">
          <span>{{profile.user_firstname}} {{profile.user_lastname}}</span>
          <br />
          <small>Employee No:{{profile.unique_id || 'Not available'}}</small>
          <br />
          <small>Date of Birth: {{ profile.date_of_birth && profileprofile.date_of_birth.split('-').reverse().join('-') || 'Not available'}}</small>
          <br />
          <small>Mobile: {{profile.phone || 'Not available'}}</small>
        </v-col>
        <v-col cols="12" md="3" class="px-0" align-self="stretch">
          <v-btn
            color="blue darken-4"
            small
            class="w-100 h-100 white--text text-capitalize font-weight-bold"
          >
            <small class="font-weight-bold">
              <v-icon small class="mb-3">mdi-account-circle</v-icon>
              <br />Click
              to
              view
              <br />ID
            </small>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "dashboard-profile",
  computed: mapGetters({ profile: "user" })
};
</script>
<style scoped>
/* .profile-img {
  border-radius: 50%;
  height: 90%;
  width: 100%;
} */
</style>
